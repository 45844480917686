







































































import { Component, Vue } from "vue-property-decorator";
import AuthService from "@/services/AuthService";
import LocationService from "../services/LocationService";
import router from "../router/index";

@Component
export default class Settings extends Vue {
  private hasError: boolean = false;
  private user: any = { roles: [], services: [] };
  private locations: any[] = [];
  private services: any[] = [];

  canAddAlternateEmail() {
    return this.user.alternateEmail.length == 0 || this.user.alternateEmail.filter((e: string) => !e || e === "").length === 0;
  }

  addAlternateEmail() {
    this.user.alternateEmail.push("");
  }

  deleteAlternateEmail(i: number) {
    this.user.alternateEmail.splice(i, 1);
  }

  save() {
    this.user.alternateEmail = this.user.alternateEmail.filter((e: string) => !!e && e.length > 0);

    AuthService.save(this.user)
      .then((response) => {
        this.user = response.data;
        router.push({ name: "home" });
      })
      .catch((e) => {
        console.log(e);
        this.hasError = true;
      });
  }

  cancel() {
    router.push({ name: "home" });
  }

  canSave() {
    return !this.user.roles.includes("ROLE_STAFF") || (this.user.location && this.user.services.length > 0);
  }

  register() {
    this.user.roles = ["ROLE_STAFF"];
  }

  mounted() {
    LocationService.all("name").then((loc) => {
      this.locations = loc.data.content;
    });

    AuthService.services().then(
      (response) =>
      (this.services = response.data.map(
        (s: string) => <any>{ value: s, text: s }
      ))
    );

    AuthService.user()
      .then((response) => (this.user = response.data))
      .catch((e) => {
        console.log(e);
        this.hasError = true;
      });
  }
}
